import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import { useTransition, animated } from "react-spring"
import PropTypes from "prop-types"
import styled from "styled-components"
import Logo from "../../../static/assets/Menu_logo.svg"

const StickyBox = styled.div`
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
  backdrop-filter: blur(6px);
`

const NavBox = styled.div`
  position: relative;
  height: 50px;
  display: flex;
  justify-content: space-between;
  margin: 0 12px;
`

const Toggle = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
`

const Menu = styled(animated.div)`
  position: relative;
  width: 100%;
  color: #000;

  a {
    display: block;
    width: fit-content;
    font-size: 1.25em;
    line-height: 100%;
    padding: 0.5em 0;
    margin-left: auto;
    margin-right: 32px;

    @media (max-width: 768px) {
      font-weight: 600;
    }
  }
`

const Hamburger = styled.div`
  background-color: #000;
  width: 30px;
  height: 2px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 2px;
    background-color: #000;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const NavBar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const navbar = useRef()
  const [safari, isSafari] = useState(false)

  const appear = useTransition(navbarOpen, {
    from: { right: "-50%", height: "0em" },
    enter: { height: "16em", right: "0%" },
    leave: { right: "-50%", height: "0em" },
  })

  useEffect(() => {
    //Safari check because gradient-fill doesn't look right.
    if (
      !!navigator.userAgent.match(/safari/i) &&
      !navigator.userAgent.match(/chrome/i) &&
      typeof document.body.style.webkitFilter !== "undefined" &&
      !window.chrome
    ) {
      isSafari(true)
    }
  }, [safari])

  return (
    <StickyBox>
      <NavBox>
        <div style={{ height: "inherit" }}>
          <Link to="/#">
            <Logo />
          </Link>
        </div>
        <Toggle onClick={() => setNavbarOpen(!navbarOpen)} ref={navbar}>
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
      </NavBox>
      {appear(
        (appear, item) =>
          item && (
            <Menu onClick={() => setNavbarOpen(!navbarOpen)} style={appear}>
              <Link className="nav-item" to="/">
                HOME
              </Link>
              <Link className="nav-item" to="/strategies">
                STRATEGIES
              </Link>
              <Link className="nav-item" to="/discussion">
                DISCUSSION
              </Link>
              <Link className="nav-item" to="/contact">
                CONTACT
              </Link>
              <a
                className="nav-item"
                href="https://cubecapital.myclientreports.com"
                rel="noreferrer"
                target="_blank"
              >
                Login
              </a>
            </Menu>
          )
      )}
    </StickyBox>
  )
}

NavBar.propTypes = {
  navbarOpen: PropTypes.bool,
  landingImage: PropTypes.node,
}

export default NavBar
