import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div `
    padding: 12px;
    min-height: 100vh;

    &.odd {
        background: radial-gradient(44.01% 61.87% at 48.49% 50.06%, #FFFFFF 0%, #E1E2E3 100%);
        box-shadow: inset 0px -9px 33px rgba(0, 0, 0, 0.25), inset 0px 25px 34px rgba(0, 0, 0, 0.25);
        background-size: 50%;
        background-position: right -19vw bottom -14vh;
        background-repeat: no-repeat;
    }

    &.even {
        background-color: white;
        background-size: contain;
        background-position: left -16vw bottom 0;
        background-repeat: no-repeat;
    }
`
//When the component is instanced, created a count that counts-up. each instances count will be equivalent to it's key.
let instancesCount = 0

const Section = ({children}) => {
    const [backing, setBacking] = useState(false);

    useEffect(() => {
        instancesCount += 1
        //If it's odd, set the className accordingly.   
        if (instancesCount % 2 === 1) {
            setBacking(true)
        }

        return () => {
          instancesCount -= 1

        }
      }, [backing]);


    
    return (
        <Container className={`${backing ? "odd" : "even"}`}
            style={{backgroundImage: backing ? `url('assets/CubeSubFrame.svg')` : `url('assets/silhouette.svg')`}}>
            {children}
        </Container>
    )
}

export default Section;