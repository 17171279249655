import * as React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import NavBar from "./UI/nav"
import Footer from "./UI/footer"

const Layout = ({ children }) => {

  return (
    <>
      <NavBar />
        <main>
          {children}
        </main>
      <Footer />
    </>  
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
