import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Link } from "gatsby-plugin-modal-routing-3"
import { LinkedInIcon, TwitterIcon } from "./Buttons"

const Foot = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 30px;
  justify-content: space-around;
  align-items: center;

  .subscript {
    font-size: 0.6em;
  }
`
const Icons = styled.div`
  display: flex;
  justify-content: space-around;

  * {
    fill: #000;
    width: 50%;

    :hover {
      fill: var(--tuva-orange-color);
      transform: scale(1.05);
      transition: 0.2s;
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "footer" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                disclaimer
              }
            }
          }
        }
      }
    }
  `)
  const { disclaimer } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const year = new Date()

  return (
    <Foot>
      <Link className="nav-item" to="/">
        © {year.getFullYear()} Cube Capital Ltd{" "}
      </Link>
      <Link className="nav-item" to="/cookie-policy" asModal>
        Cookie Policy
      </Link>
      <Link className="nav-item" to="/privacy-policy" asModal>
        Privacy Policy
      </Link>
      <Link className="nav-item" to="/terms" asModal>
        Terms & Conditions
      </Link>
      <Link className="nav-item" to="/contact">
        Contact Us
      </Link>
      <a
        className="nav-item"
        href="https://myclientreports.com/CubeCapital"
        rel="noreferrer"
        target="_blank"
      >
        Login
      </a>
      <Icons>
        <LinkedInIcon />
        <TwitterIcon />
      </Icons>
      <p className="subscript">{disclaimer}</p>
    </Foot>
  )
}

export default Footer
