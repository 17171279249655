import React from "react"
import styled from "styled-components"
import {
  useSpring,
  animated,
  useSpringRef,
  useTransition,
  useChain,
} from "react-spring"
import VisibilitySensor from "react-visibility-sensor"
import { GatsbyImage } from "gatsby-plugin-image"

const Bar = styled.div`
  height: 4px;
  background-color: #00adee;
  position: relative;
  margin-bottom: 12px;

  &.left {
    margin-left: calc(-1 * (var(--body-margin-sides)) - 12px);
    transform-origin: top left;
  }

  &.right {
    margin-right: calc(-1 * (var(--body-margin-sides)) - 12px);
    transform-origin: top right;
  }
`

const ImageBox = styled.div`
  margin-top: 1em;
  box-shadow: var(--cube-box-shadow);
`

const QuoteBox = styled.div`
  position: absolute;
  right: -50px;
  padding: 24px;
  max-width: 70%;
  bottom: -80px;
  background: var(--cube-grad-blue);
  color: white;
  font-size: calc(1em + 1vh);
  box-shadow: var(--cube-box-shadow);

  @media (max-width: 768px) {
    bottom: -90px;
    max-width: 80%;
    margin-right: 22px;
    font-size: calc(0.75em + 1vh);
  }
`
const Spring = ({ isVisible, image, excerpt }) => {
  // Build a spring and catch its ref
  const springRef = useSpringRef()
  const props = useSpring({
    to: {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translateX(0%)" : "translateX(100%)",
    },
    delay: 100,
    ref: springRef,
  })
  // Build a transition and catch its ref
  const transitionRef = useSpringRef()
  const transitions = useTransition(isVisible, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translateX(0%)" : "translateX(100%)",
    },
    leave: {
      opacity: isVisible ? 0 : 1,
      transform: isVisible ? "translateX(100%)" : "translateX(0%)",
    },
    delay: 100,
    ref: transitionRef,
  })
  // First run the spring, when it concludes run the transition
  useChain([springRef, transitionRef])
  // Use the animated props like always
  return (
    <>
      <animated.div style={props}>
        <ImageBox>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="street"
          />
        </ImageBox>
      </animated.div>
      {transitions(styles => (
        <animated.div style={styles}>
          <QuoteBox>
            <p>{excerpt}</p>
          </QuoteBox>
        </animated.div>
      ))}
    </>
  )
}

//Component that react-springs the child up or down
const FadeUp = ({ isVisible, children, direction }) => {
  const up = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0px)" : "translateY(50px)",
  })
  const down = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? "translateY(0px)" : "translateY(-50px)",
  })
  return <animated.div style={direction ? up : down}>{children}</animated.div>
}

//Title Reveal with props for left or right aligned bar and spring up/down.
export const RevealTitle = ({ children, barStyle, directionUp }) => {
  return (
    <VisibilitySensor>
      {({ isVisible }) => {
        return (
          <div>
            {directionUp ? (
              <>
                <FadeUp isVisible={isVisible} direction={directionUp}>
                  {children}
                </FadeUp>
                <Bar
                  className={`${barStyle}`}
                  style={{
                    transform: isVisible ? "scaleX(1.0)" : "scaleX(0.1)",
                    transition: "all 0.5s ease",
                  }}
                />
              </>
            ) : (
              <>
                <Bar
                  className={`${barStyle}`}
                  style={{
                    transform: isVisible ? "scaleX(1.0)" : "scaleX(0.1)",
                    transition: "all 0.5s ease",
                  }}
                />
                <FadeUp isVisible={isVisible} direction={directionUp}>
                  {children}
                </FadeUp>
              </>
            )}
          </div>
        )
      }}
    </VisibilitySensor>
  )
}

export const ImageOnScroll = ({ image, excerpt }) => {
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return (
          <div>
            <Spring isVisible={isVisible} image={image} excerpt={excerpt} />
          </div>
        )
      }}
    </VisibilitySensor>
  )
}

export const RevealChild = ({ children }) => {
  const Reveal = ({ isVisible, children }) => {
    const props = useSpring({
      to: {
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateX(0%)" : "translateX(100%)",
      },
      delay: 100,
    })
    return (
      <animated.div style={props} isVisible={isVisible}>
        {children}
      </animated.div>
    )
  }
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return <Reveal isVisible={isVisible}>{children}</Reveal>
      }}
    </VisibilitySensor>
  )
}
